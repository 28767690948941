import { IntlShape, useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import {
  ApifyLogo,
  AtlassianLogo,
  AwsLogo,
  GoodDataLogo,
  KeboolaLogo,
  KenticoLogo,
  KpmLogo,
  MicrosoftLogo
} from "../../assets/images/Images";
import FlexBox from "../../components/FlexBox";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";

const PartnersWrapper = styled.div`
  display: flex;
  max-width: ${rem(1200)};
  margin: ${rem(120)} auto 0 0;
  flex-wrap: wrap;
`;

const PartnerWrapper = styled(FlexBox)`
  width: 25%;
  margin-bottom: ${rem(30)};
  ${({ theme }): string[] => [theme.media.maxM]} {
    width: 50%;
  }
`;

const PartnerText = styled.span`
  font-size: ${rem(12)};
  letter-spacing: 2px;
  color: ${({ theme }): string => theme.colors.gray};
  text-transform: uppercase;
  margin-bottom: ${rem(20)};
  text-align: center;
  ${({ theme }): string[] => [theme.media.maxS]} {
    height: ${rem(40)};
  }
`;

const PartnerLogoWrapper = styled.div`
  margin-top: ${rem(20)};
`;

const PartnerLogo = styled.img`
  max-width: ${rem(130)};
  max-height: ${rem(45)};
  transform: translateY(-50%);
  ${({ theme }): string[] => [theme.media.maxS]} {
    max-width: ${rem(130)};
  }
`;

type PartnerItem = {
  title: string;
  logo: string;
};
type Partners = PartnerItem[];
type getPartnersT = (intl: IntlShape) => Partners;
const getPartners: getPartnersT = intl => {
  return [
    {
      title: intl.formatMessage({ id: "home.about.partnerSilver" }),
      logo: AtlassianLogo
    },
    {
      title: intl.formatMessage({ id: "home.about.partnerSelected" }),
      logo: AwsLogo
    },
    {
      title: intl.formatMessage({ id: "home.about.partnerSilver" }),
      logo: KenticoLogo
    },
    {
      title: intl.formatMessage({ id: "home.about.partnerMain" }),
      logo: KpmLogo
    },
    {
      title: intl.formatMessage({ id: "home.about.partnerConsulting" }),
      logo: KeboolaLogo
    },
    {
      title: intl.formatMessage({ id: "home.about.partnerSolution" }),
      logo: ApifyLogo
    },
    {
      title: intl.formatMessage({ id: "home.about.partnerConsulting" }),
      logo: GoodDataLogo
    },
    {
      title: intl.formatMessage({ id: "home.about.justPartner" }),
      logo: MicrosoftLogo
    }
  ];
};

const Partners: FC = () => {
  const intl = useIntl();

  return (
    <PartnersWrapper>
      {getPartners(intl).map((partner, index) => (
        <PartnerWrapper key={index} flexDirection="column" alignItems="center">
          <PartnerText>{partner.title}</PartnerText>
          <PartnerLogoWrapper>
            <PartnerLogo src={partner.logo} alt={partner.title} />
          </PartnerLogoWrapper>
        </PartnerWrapper>
      ))}
    </PartnersWrapper>
  );
};

export default React.memo(Partners);
