import React, { ComponentType, FC, useState } from "react";

import styled from "../styling/styled";
import { rem } from "../styling/theme";

const CAPABAILITY_EASING = "cubic-bezier(0.000, 0.550, 0.700, 1);";

const CapabilityWrapper = styled.div<CapabilityModalStyleT>`
  width: ${({ modal }): string => (modal ? "23%" : "30%")};
  height: ${rem(150)};
  margin-bottom: ${rem(20)};
  margin-left: ${({ modal }): string => (modal ? "2%" : "3.33%")};
  position: relative;
  ${({ theme }): string[] => [theme.media.maxLg]} {
    margin-left: ${({ modal }): string => (modal ? "8%" : "20%")};
  }
  ${({ theme }): string[] => [theme.media.maxL]} {
    width: 30%;
    justify-content: center;
    margin-left: 3.33%;
    height: ${({ modal }): string => (modal ? rem(130) : rem(150))};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    width: 50%;
    margin-left: 0;
    display: flex;
    justify-content: center;
    height: ${({ modal }): string => (modal ? rem(100) : rem(150))};
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    height: ${({ modal }): string => (modal ? rem(80) : rem(150))};
  }
`;

const OverflowWrapper = styled.div<HoverStylePropsT>`
  left: 0;
  width: ${({ hover }): string => (hover ? "80px" : "0px")};
  height: 100%;
  position: absolute;
  background-color: ${({ theme }): string => theme.colors.primary};
  overflow: hidden;
  transition: ${"width 350ms " + CAPABAILITY_EASING};
  ${({ theme }): string[] => [theme.media.maxM]} {
    display: none;
  }
`;

const FirstLayerWrapper = styled.div`
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  ${({ theme }): string[] => [theme.media.maxM]} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const Title = styled.div<ColorStyleT>`
  display: block;
  position: absolute;
  font-size: ${rem(11)};
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: 110px;
  color: ${({ theme, color }): string => theme.colors[color]};
  left: ${rem(40)};
  top: ${rem(80)};
  ${({ theme }): string[] => [theme.media.maxM]} {
    position: relative;
    left: auto;
    top: auto;
    width: auto;
    text-align: center;
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    font-size: ${rem(10)};
  }
`;

export type CapabilityItemsT = CapabilityItemT[];
type CapabilityItemT = {
  title: string;
  icon: ComponentType;
  main?: boolean;
};

type CapabilityItemPropsT = {
  item: CapabilityItemT;
  modal?: boolean;
};

type ColorStyleT = {
  color: "black" | "white";
};

type HoverStylePropsT = {
  hover: boolean;
};

type CapabilityModalStyleT = {
  modal?: boolean;
};

const CapabilityItem: FC<CapabilityItemPropsT> = ({ item, modal }) => {
  const [hover, setHover] = useState(false);
  const Symbol = styled(item.icon)<ColorStyleT>`
    max-height: ${rem(40)};
    width: ${rem(55)};
    position: absolute;
    top: 20px;
    transform: translate(25%);
    path {
      fill: ${({ theme, color }): string => theme.colors[color]};
    }
    ${({ theme }): string[] => [theme.media.maxM]} {
      position: relative;
      transform: translate(0);
      top: auto;
      width: auto;
      margin-bottom: ${rem(10)};
    }
  `;

  return (
    <CapabilityWrapper
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      modal={modal}
    >
      <FirstLayerWrapper>
        <Symbol color="white" />
        <Title color="white"> {item.title}</Title>
      </FirstLayerWrapper>
      <OverflowWrapper hover={hover}>
        <Symbol color="black" />
        <Title color="black">{item.title}</Title>
      </OverflowWrapper>
    </CapabilityWrapper>
  );
};

export default CapabilityItem;
