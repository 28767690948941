import React, { FC } from "react";

import styled from "../styling/styled";
import { rem } from "../styling/theme";

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 10;
`;

const VideoComponent = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  max-width: ${rem(750)};
  max-height: ${rem(500)};
  background-color: ${({ theme }): string => theme.colors.black};
`;

const CloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: 10%;
  right: 15%;
  width: ${rem(50)};
  height: ${rem(50)};
`;

const Line = styled.div`
  position: relative;
  top: ${rem(25)};
  width: ${rem(60)};
  height: ${rem(2)};
  background-color: ${({ theme }): string => theme.colors.primary};
`;

const Xfirst = styled(Line)`
  transform: rotate(45deg);
`;

const Xsecond = styled(Line)`
  transform: rotate(-45deg);
`;

type VideoModalT = {
  visible: boolean;
  src: string;
  close: () => void;
};

const VideoModal: FC<VideoModalT> = ({ visible, src, close }) => {
  if (visible) {
    return (
      <Wrapper>
        <CloseIcon onClick={close}>
          <Xfirst />
          <Xsecond />
        </CloseIcon>
        <VideoComponent
          src={src}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      </Wrapper>
    );
  }
  return null;
};

export default VideoModal;
