import React, { FC } from "react";

import { useResize } from "../hooks/useResize";
import styled from "../styling/styled";
import { rem } from "../styling/theme";
import Arrow, { ARROW_EASING, ARROW_TIME } from "./Arrow";

const BUBBLE_WIDTH = 100;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  bottom: ${rem(20)};
  left: ${rem(-150)};
  width: ${rem(200)};
  cursor: pointer;
  ${({ theme }): string[] => [theme.media.maxL]} {
    bottom: auto;
    left: auto;
    width: auto;
    flex-direction: column-reverse;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
`;

const Text = styled.span<ActiveStylePropsT>`
  width: 100%;
  position: relative;
  left: ${({ active }): string => (active ? "0" : "-10px")};
  opacity: ${({ active }): number => (active ? 1 : 0)};
  font-size: ${rem(16)};
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
  display: inline-block;
  transition: ${"all " + ARROW_TIME + " " + ARROW_EASING};
  transition-delay: ${({ active }): string => (active ? "0s" : ARROW_TIME)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    width: auto;
    margin-top: ${rem(BUBBLE_WIDTH / 1.5)};
    left: auto;
  }
`;

type ActiveStylePropsT = {
  active: boolean;
};

type ArrowTextT = {
  active: boolean;
  text: string;
};

const ArrowText: FC<ArrowTextT> = ({ active, text }) => {
  const { mobileVersion } = useResize();
  return (
    <Wrapper>
      <Text active={active}>{text}</Text>
      <Arrow
        width={mobileVersion ? 30 : 190}
        visible={active}
        bubbleWidth={BUBBLE_WIDTH}
      />
    </Wrapper>
  );
};

export default ArrowText;
