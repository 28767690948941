import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useEffect, useRef, useState } from "react";

import {
  AtlassianSymbol,
  BusinessInteligenceSymbol,
  BusinessSymbol,
  CommunicationToolsSymbol,
  CrmSymbol,
  CustomDevelopmentSymbol,
  CustomerSupportSymbol,
  DesignSymbol,
  DevelopmentSymbol,
  ECommerceSymbol,
  IOTSymbol,
  InfrastructureSymbol,
  MobileAplicationSymbol,
  SapSymbol,
  TeamLeasingSymbol,
  TestingSymbol
} from "../../assets/images/Images";
import Button from "../../components/Button";
import CapabilityItem, {
  CapabilityItemsT
} from "../../components/CapabilityItem";
import Container from "../../components/Container";
import FlexBox from "../../components/FlexBox";
import Heading from "../../components/Heading";
import { MenuItemsIDMenu, getSectionHook } from "../../components/Menu";
import Modal from "../../components/Modal";
import MovingBlock from "../../components/MovingBlock";
import Paragraph from "../../components/Paragraph";
import Spacer from "../../components/Spacer";
import Texture from "../../components/Texture";
import { useFillSections } from "../../hooks/useFillSections";
import { useResize } from "../../hooks/useResize";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { HookSectionPropsT } from "../../utils/scrolledSection";
import { ToggleStatusT } from "../SideBar";

export const TextWrapper = styled.div`
  width: 45%;
  z-index: 1;
  ${({ theme }): string[] => [theme.media.maxL]} {
    width: 50%;
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    width: 100%;
  }
`;

const ItemList = styled.div`
  width: 55%;
  display: flex;
  flex-wrap: wrap;
  padding: ${rem(150)} 0 ${rem(100)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    width: 100%;
    padding: ${rem(50)} 0;
  }
`;

const ModalList = styled.div`
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  padding: ${rem(100)} 0;
  margin: 0 auto;
  ${({ theme }): string[] => [theme.media.maxL]} {
    width: 100%;
    padding: ${rem(100)} ${rem(50)} ${rem(100)} 0;
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    width: 100%;
    padding: ${rem(90)} ${rem(20)} ${rem(50)};
  }
`;

const CapabilityParagraph = styled(Paragraph)`
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(14)};
  }
`;

export const cabapilities: CapabilityItemsT = [
  {
    title: "E-commerce",
    icon: ECommerceSymbol,
    main: true
  },
  {
    title: "Web Development",
    icon: DevelopmentSymbol,
    main: true
  },
  {
    title: "Business Intelligence",
    icon: BusinessInteligenceSymbol,
    main: true
  },
  {
    title: "SAP",
    icon: SapSymbol,
    main: true
  },
  {
    title: "Atlassian",
    icon: AtlassianSymbol,
    main: true
  },
  {
    title: "Infrastructure",
    icon: InfrastructureSymbol,
    main: true
  },
  {
    title: "UI/UX Design",
    icon: DesignSymbol,
    main: true
  },
  {
    title: "Team leasing",
    icon: TeamLeasingSymbol,
    main: true
  },
  {
    title: "Mobile Application",
    icon: MobileAplicationSymbol,
    main: true
  },
  {
    title: "Custom Development",
    icon: CustomDevelopmentSymbol
  },
  {
    title: "CRM / ERP",
    icon: CrmSymbol
  },
  {
    title: "IOT",
    icon: IOTSymbol
  },
  {
    title: "Testing",
    icon: TestingSymbol
  },
  {
    title: "Communication tools",
    icon: CommunicationToolsSymbol
  },
  {
    title: "Marketing",
    icon: BusinessSymbol
  },
  {
    title: "Consulting",
    icon: CustomerSupportSymbol
  }
];

const Capabilities: FC<ToggleStatusT & HookSectionPropsT> = ({
  open,
  setSections
}) => {
  const intl = useIntl();
  const { mobileVersion } = useResize();

  const capabalityID: MenuItemsIDMenu = "capabalities";
  const capabalityRef = useRef<HTMLDivElement>(null);
  useFillSections(setSections, capabalityID, capabalityRef);

  const [modal, setModal] = useState(false);

  const getMainCapabilities: CapabilityItemsT = cabapilities.filter(
    item => item.main
  );

  useEffect(() => {
    modal
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "visible");
  }, [modal]);

  return (
    <Container ref={capabalityRef} id={getSectionHook(capabalityID)}>
      {!mobileVersion && (
        <MovingBlock open={open} top={220}>
          <Texture width={rem(400)} height={rem(150)} />
        </MovingBlock>
      )}
      <FlexBox
        alignItems="center"
        flexDirection={mobileVersion ? "column" : "row"}
      >
        <TextWrapper>
          <Spacer mobileSize="large" />
          <Heading
            pre={intl.formatMessage({ id: "home.capabilities.pre" })}
            title={intl.formatMessage({ id: "home.capabilities.title" })}
          />
          <CapabilityParagraph
            maxWidth={375}
            paragraph={intl.formatMessage({
              id: "home.capabilities.paragraph"
            })}
          />
          <Spacer mobileSize="medium" size="small" />
          <Button
            text={intl.formatMessage({
              id: "home.capabilities.button"
            })}
            modal={(): void => setModal(true)}
          />
        </TextWrapper>
        <ItemList>
          {getMainCapabilities.map((item, index) => (
            <CapabilityItem key={index} item={item} />
          ))}
        </ItemList>
      </FlexBox>
      <Modal visible={modal} close={(): void => setModal(false)}>
        <ModalList>
          {cabapilities.map((item, index) => (
            <CapabilityItem key={index} item={item} modal={true} />
          ))}
        </ModalList>
      </Modal>
    </Container>
  );
};

export default React.memo(Capabilities);
