import { useIntl } from "gatsby-plugin-intl";
import React, { FC, ReactNode } from "react";

import { CloseIcon, ModalBg } from "../assets/images/Images";
import styled from "../styling/styled";
import { rem } from "../styling/theme";

const Wrapper = styled.div`
  position: fixed;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-image: url(${ModalBg});
  background-size: auto 100%;
  top: 0;
  left: 0;
  z-index: 10;
`;

const CloseWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: 10%;
  right: 8%;
  text-align: center;
  ${({ theme }): string[] => [theme.media.maxL]} {
    top: 25px;
    right: 25px;
  }
`;

const CloseText = styled.div`
  font-size: ${rem(11)};
  opacity: 0.5;
  text-transform: uppercase;
`;

type ModalT = {
  visible: boolean;
  close: () => void;
  children: ReactNode;
};

const Modal: FC<ModalT> = ({ visible, close, children }) => {
  const intl = useIntl();

  if (visible) {
    return (
      <Wrapper>
        <CloseWrapper onClick={close}>
          <CloseIcon />
          <CloseText>
            {intl.formatMessage({
              id: "modal.close"
            })}
          </CloseText>
        </CloseWrapper>
        {children}
      </Wrapper>
    );
  }
  return null;
};

export default Modal;
