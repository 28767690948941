import React, { FC } from "react";

import Layout from "../components/Layout";
import About from "../partials/Homepage/About";
import Capabilities from "../partials/Homepage/Capabilities";
import Contact from "../partials/Homepage/Contact";
import Introduction from "../partials/Homepage/Introduction";
import SuccessStories from "../partials/Homepage/SuccessStories";

const HomePage: FC = () => {
  return (
    <Layout
      helmetKey="home"
      render={(open, setSections): React.ReactChild => (
        <>
          <Introduction />
          <Capabilities setSections={setSections} open={open} />
          <SuccessStories setSections={setSections} />
          <About setSections={setSections} open={open} />
          <Contact setSections={setSections} />
        </>
      )}
    />
  );
};

export default HomePage;
