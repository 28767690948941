import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { ConcreteTemplate, TextureTemplate } from "../../assets/images/Images";
import Container from "../../components/Container";
import EdgeBlock from "../../components/EdgeBlock";
import PuppetText from "../../components/PuppetText";
import ScrollDown from "../../components/ScrollDown";
import Spacer from "../../components/Spacer";
import { useResize } from "../../hooks/useResize";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { getCities } from "./Cities";

const ConcreteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${rem(750)};
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: ${rem(50)};
  position: relative;
  transform: translate3d(0, 0, 0);
  ${({ theme }): string[] => [theme.media.maxL]} {
    max-width: ${rem(635)};
    padding-top: ${rem(250)};
    margin-bottom: ${rem(50)};
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
    margin: 0 auto;
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    max-width: ${rem(450)};
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
  }
  ${({ theme }): string[] => [theme.media.maxXs]} {
    max-width: ${rem(280)};
    padding-left: ${rem(0)};
    padding-right: ${rem(0)};
  }
`;

const PuppetWrapper = styled.div`
  ${({ theme }): string[] => [theme.media.maxL]} {
    display: flex;
    justify-content: center;
  }
  ${({ theme }): string[] => [theme.media.maxXs]} {
    margin-top: ${rem(30)};
    justify-content: space-between;
  }
`;

const Smart = styled.span`
  color: ${({ theme }): string => theme.colors.primary};
  font-size: ${rem(18)};
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: ${rem(10)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    margin-bottom: ${rem(-10)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(14)};
  }
  &:before {
    content: "<";
  }
  &:after {
    content: ">";
  }
`;

const TextureSide = styled.div<DimensionsStyleProps>`
  width: ${({ width }): string => (width ? rem(width) : "auto")};
  height: ${({ height }): string => (height ? rem(height) : "auto")};
  background-image: url(${TextureTemplate});
`;

const ConcreteHeading = styled.h1`
  position: relative;
  font-family: "Overpass Mono";
  background-image: url(${ConcreteTemplate});
  background-attachment: fixed;
  -webkit-background-clip: text;
  background-clip: text;
  letter-spacing: 15px;
  margin: 0;
  text-transform: uppercase;
  ${({ theme }): string[] => [theme.media.maxXs]} {
    letter-spacing: 12px;
  }
`;

const Main = styled(ConcreteHeading)`
  font-size: ${rem(130)};
  line-height: ${rem(130)};
  color: transparent;
  ${({ theme }): string[] => [theme.media.maxL]} {
    font-size: ${rem(90)};
    line-height: ${rem(110)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(60)};
    line-height: ${rem(80)};
  }
  ${({ theme }): string[] => [theme.media.maxXs]} {
    font-size: ${rem(38)};
    line-height: ${rem(65)};
  }
`;

const Sub = styled(ConcreteHeading)`
  top: ${rem(-30)};
  font-size: ${rem(90)};
  color: #101010;
  -webkit-text-stroke: 4px transparent;
  align-self: flex-end;
  ${({ theme }): string[] => [theme.media.maxL]} {
    font-size: ${rem(60)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(40)};
    top: ${rem(-20)};
  }
  ${({ theme }): string[] => [theme.media.maxXs]} {
    font-size: ${rem(30)};
    -webkit-text-stroke: 3px transparent;
  }
`;

type DimensionsStyleProps = {
  width: number;
  height: number;
};

const Introduction: FC = () => {
  const intl = useIntl();
  const { mobileVersion, windowWidth, windowHeight } = useResize();

  return (
    <>
      <Container height={mobileVersion ? "auto" : "100vh"}>
        <ConcreteWrapper>
          <ScrollDown />
          <Smart>{intl.formatMessage({ id: "home.intro.smart" })}</Smart>
          <Main>{intl.formatMessage({ id: "home.intro.software" })}</Main>
          <Sub>{intl.formatMessage({ id: "home.intro.garage" })}</Sub>
        </ConcreteWrapper>
        <PuppetWrapper>
          <PuppetText
            isFirst={true}
            number="350+"
            text={intl.formatMessage({ id: "home.intro.members" })}
          />
          <PuppetText
            number={`${Object.keys(getCities(intl)).length}`}
            text={intl.formatMessage({ id: "home.intro.cities" })}
          />
        </PuppetWrapper>
        <EdgeBlock top={0} sideDistance={-windowWidth / 1.7} side="right">
          <TextureSide height={windowHeight} width={windowWidth} />
        </EdgeBlock>
      </Container>

      {mobileVersion && <Spacer size="extraLarge" />}
    </>
  );
};

export default React.memo(Introduction);
