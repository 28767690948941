import { useIntl } from "gatsby-plugin-intl";
import React, { FC, useRef, useState } from "react";
import ReactPlayer from "react-player";

import { VideoThumb } from "../../assets/images/Images";
import ArrowText from "../../components/ArrowText";
import FlexBox from "../../components/FlexBox";
import Heading from "../../components/Heading";
import { MenuItemsIDMenu, getSectionHook } from "../../components/Menu";
import MovingBlock from "../../components/MovingBlock";
import Paragraph from "../../components/Paragraph";
import Spacer from "../../components/Spacer";
import Texture from "../../components/Texture";
import VideoModal from "../../components/VideoModal";
import { useFillSections } from "../../hooks/useFillSections";
import { useResize } from "../../hooks/useResize";
import styled from "../../styling/styled";
import { theme as importedTheme, rem } from "../../styling/theme";
import { HookSectionPropsT } from "../../utils/scrolledSection";
import { ToggleStatusT } from "../SideBar";
import { TextWrapper } from "./Capabilities";
import { BackgroundContainer } from "./Contact";
import Partners from "./Partners";

const VideoWrapper = styled.div`
  width: 35%;
  margin-left: 10%;
  position: relative;
  z-index: 0;
  cursor: pointer;
  ${({ theme }): string[] => [theme.media.maxL]} {
    width: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    margin-left: 0%;
  }
`;

const Shape = styled.div`
  position: absolute;
  top: ${rem(-50)};
  left: ${rem(-80)};
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: ${({ theme }): string => theme.colors.black};
`;

const VideoStyles = `
  position: relative;
  display: block;
  width: 100%;
  z-index: 1;
  ${[importedTheme.media.maxL]} {
    margin: ${rem(20)} auto 0 auto;
    max-width: ${rem(450)};
    opacity: 0.7;
  }
  `;

const Video = styled(ReactPlayer)`
  ${VideoStyles};
`;

const VideoThumbnail = styled.img`
  ${VideoStyles};
`;

const About: FC<ToggleStatusT & HookSectionPropsT> = ({
  open,
  setSections
}) => {
  const intl = useIntl();
  const { mobileVersion } = useResize();
  const [hover, setHover] = useState(false);
  const [videoModal, setVideoModal] = useState(false);

  const aboutID: MenuItemsIDMenu = "about";
  const aboutRef = useRef<HTMLDivElement>(null);
  useFillSections(setSections, aboutID, aboutRef);

  return (
    <BackgroundContainer
      ref={aboutRef}
      id={getSectionHook("about")}
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      layer={1}
    >
      {!mobileVersion && <Spacer size="extraLarge" />}
      <FlexBox
        alignItems="center"
        flexDirection={mobileVersion ? "column" : "row"}
      >
        <TextWrapper>
          <Heading
            pre={intl.formatMessage({ id: "home.about.pre" })}
            title={intl.formatMessage({ id: "home.about.title" })}
          >
            {!mobileVersion && (
              <MovingBlock open={open} top={170}>
                <Texture width={rem(400)} height={rem(150)} />
              </MovingBlock>
            )}
          </Heading>
          <Paragraph
            maxWidth={375}
            paragraph={intl.formatMessage({
              id: "home.about.paragraph"
            })}
          />
        </TextWrapper>
        <VideoWrapper onClick={(): void => setVideoModal(true)}>
          {mobileVersion ? (
            <VideoThumbnail
              src={VideoThumb}
              alt={intl.formatMessage({ id: "home.about.play" })}
            />
          ) : (
            <>
              <Shape />
              <div>
                <Video
                  url={require("../../../static/video/BIQ-thumbnail.mp4")}
                  playing={!videoModal}
                  loop={true}
                  controls={false}
                  width="370"
                  height="350"
                  muted={true}
                />
              </div>
            </>
          )}
          <ArrowText
            text={intl.formatMessage({ id: "home.about.play" })}
            active={mobileVersion ? true : hover}
          />
        </VideoWrapper>
      </FlexBox>
      <VideoModal
        visible={videoModal}
        close={(): void => setVideoModal(false)}
        src="https://www.youtube.com/embed/ekFbO2RipWc?autoplay=1"
      />
      <Partners />
    </BackgroundContainer>
  );
};

export default React.memo(About);
