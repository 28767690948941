import {} from "../styling/theme";

import React, { FC } from "react";

import styled from "../styling/styled";
import { rem } from "../styling/theme";

const SAFE_AREA = 10;

const Wrapper = styled.div<PuppetFirstStyleProps>`
  top: ${({ isFirst }): string => (isFirst ? rem(200) : rem(500))};
  right: ${({ isFirst }): string => (isFirst ? rem(200) : rem(100))};
  position: absolute;
  display: flex;
  flex-direction: column;
  line-height: ${rem(30)};
  ${({ theme }): string[] => [theme.media.maxL]} {
    top: ${({ isFirst }): string => (isFirst ? rem(100) : rem(160))};
    right: auto;
    left: ${({ isFirst }): string => (isFirst ? "30vw" : "65vw")};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    left: ${({ isFirst }): string => (isFirst ? "25vw" : "65vw")};
  }
  ${({ theme }): string[] => [theme.media.maxXs]} {
    left: ${({ isFirst }): string => (isFirst ? "15vw" : "65vw")};
    margin: 0;
  }
`;

const Puppet = styled.div<PuppetFirstStyleProps>`
  position: absolute;
  top: ${({ isFirst }): string => (isFirst ? rem(-200) : rem(-500))};
  width: 1px;
  height: ${({ isFirst }): string =>
    isFirst ? rem(200 + SAFE_AREA) : rem(500 + SAFE_AREA)};
  background-color: ${({ theme }): string => theme.colors.white};
  left: ${({ isFirst }): string => (isFirst ? rem(94) : rem(12))};
  opacity: 0.2;
  ${({ theme }): string[] => [theme.media.maxL]} {
    left: ${({ isFirst }): string => (isFirst ? rem(77) : rem(10))};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    left: ${({ isFirst }): string => (isFirst ? rem(55) : rem(7))};
  }
`;

const Line = styled.span`
  text-transform: uppercase;
`;

const BigLine = styled(Line)`
  font-size: ${rem(40)};
  font-family: "Overpass Mono";
  letter-spacing: 2.5px;
  ${({ theme }): string[] => [theme.media.maxL]} {
    font-size: ${rem(33)};
    letter-spacing: 2px;
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    font-size: ${rem(24)};
    letter-spacing: 1.6px;
  }
`;

const SmallLine = styled(Line)<PuppetFirstStyleProps>`
  position: relative;
  font-size: ${rem(16)};
  font-weight: 700;
  letter-spacing: 2px;
  margin-left: ${({ isFirst }): string => (isFirst ? rem(30) : "0")};
  ${({ theme }): string[] => [theme.media.maxL]} {
    font-size: ${rem(14)};
  }
  ${({ theme }): string[] => [theme.media.maxS]} {
    font-size: ${rem(11)};
  }
`;

type PuppetFirstStyleProps = {
  isFirst?: boolean;
};

type PuppetTextT = {
  isFirst?: boolean;
  text: string;
  number: string;
};

const PuppetText: FC<PuppetTextT> = ({ text, number, isFirst = false }) => {
  return (
    <Wrapper isFirst={isFirst}>
      <Puppet isFirst={isFirst} />
      <BigLine>{number}</BigLine>
      <SmallLine isFirst={isFirst}>{text}</SmallLine>
    </Wrapper>
  );
};

export default PuppetText;
